import { z } from "zod";
import { createEnv } from "@t3-oss/env-nextjs";

const clientEnvSchema = z.object({
  NEXT_PUBLIC_GROWTHBOOK_API_HOST: z.string(),
  NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY: z.string(),
  NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_API_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string(),
});

export const env = createEnv({
  client: clientEnvSchema.shape,
  runtimeEnv: {
    NEXT_PUBLIC_GROWTHBOOK_API_HOST:
      process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY:
      process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY:
      process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
    NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID:
      process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  },
  skipValidation: process.env.NODE_ENV === "test",
});
